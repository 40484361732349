import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "website-title" }
const _hoisted_3 = { class: "get-start" }
const _hoisted_4 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("nav", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toHome()))
            }, "MACHINES")
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}