
import { i18n } from "./local";
import * as config from "@/doc/frontend";
import { defineComponent } from "vue";
import router from "@/router";

const { t } = i18n.global;
export default defineComponent({
  setup() {
    return {
      config,
      toHome() {
        router.push("/");
      },
    };
  },
});
