import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { getCookie } from './cookie';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: "/login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: '/detail',
    component: () => import("@/views/DetailView.vue"),
    props: route => ({ id: route.query.id, status: route.query.status })
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.beforeEach((to) => {
  const time = Date.now();
  if (time - Number(getCookie("timer")) < 1000 * 3600 * 4) {
    document.cookie = `timer=${time}`;
  } else if (to.fullPath == "/login") {
    return true;
  } else {
    return { path: "/login" };
  }
});

export default router
